<template>
  <q-page>
    <div class="layout-padding">
      <div class="row q-col-gutter-sm">
        <div class="col-sm-12">
          <q-card class="bg-white summary bike-hire-card summary-card">
            <q-card-section class="bg-primary title">
              {{ $t('return_summary') }}
            </q-card-section>
            <q-card-section class="bike-hire-logo">
              <img
                :src="`${summary.logo}`"
                style="max-width:85%"
              >
            </q-card-section>
            <q-card-section class="bike-hire-loc-name">
              {{ $t('bikehire.return_to_place', { place: summary.return_station.name }) }}
            </q-card-section>
            <q-card-section class="bike-hire-loc-date">
              {{ $t('time.at', {time: toMediumDateTime(summary.return_time.date)}) }}
            </q-card-section>
            <q-card-section class="bike-hire-pic">
              <img :src="`${summary.picture}`" style="max-width:50%; margin-left:35%">
            </q-card-section>
          </q-card>
          <div class="col-sm-12">
            <m-banner
              color="primary"
              :message="$t('bikehire.brompton.payment_estimate')"
            />
          </div>
          <div class="col-sm-12">
            <q-btn
              color="primary"
              @click="submit"
            >
              {{ $t('bikehire.book_return') }}
            </q-btn>
          </div>
        </div>
      </div>
    </div>
  </q-page>
</template>

<script>
import { mapGetters } from 'vuex'
import store from 'store'
import i18n from 'i18n'
import loading from 'utils/loading'
import { returnSummary, bookReturn } from 'api/bikehire'
import date from 'utils/date-time'
import { MBanner } from 'components/'
import { handleErrors } from 'utils/utils'
const { toMediumDateTime } = date

export default {
  components: {
    MBanner
  },
  data () {
    return {
      summary: {
        return_station: {},
        return_time: {},
        return_token: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      bikeHireStatus: 'bikehire/status',
      token: 'ondemand/bookingToken'
    })
  },
  async beforeRouteEnter (to, from, next) {
    const token = store.getters['ondemand/bookingToken']
    const partner = store.getters['partner']

    loading.start({
      message: i18n.t('loading.preparing.booking'),
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })

    try {
      const { data } = await returnSummary(token)

      next(vm => {
        vm.summary = data
        loading.stop()
      })
    } catch (err) {
      handleErrors(err)
      next(false)
      loading.stop()
    }
  },
  methods: {
    returnSummary,
    toMediumDateTime,
    bookReturn,
    async submit () {
      await bookReturn(this.summary.return_token)
      this.$router.push({ name: 'dashboard', params: { booking_reference: this.bikeHireStatus.brompton.booking_reference } })
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '../../../styles/ondemand'
</style>
