<template>
  <q-drawer
    v-model="show"
    side="right"
    elevated
  >
    <div class="column items-stretch">
      <q-stepper
        ref="stepper"
        v-model="currentStep"
        vertical
        class="stepper row full-width no-shadow"
        header-nav
      >
        <q-step
          name="ondemand-bikehire-results"
          default
          :title="$t('bikehire.nearby_pickup')"
          :caption="$t('select_pickup_location')"
          :done="navigatable.includes('ondemand-bikehire-results')"
          :header-nav="navigatable.includes('ondemand-bikehire-results')"
        >
          <q-stepper-navigation>
            <q-btn
              @click="$router.push({name: selectSummary })"
            >
              {{ $t('continue') }}
            </q-btn>
          </q-stepper-navigation>
        </q-step>
        <q-step
          name="ondemand-bikehire-summary"
          default
          :title="$t('booking.summary')"
          :caption="$t('review_and_submit')"
          :done="navigatable.includes('ondemand-bikehire-summary')"
          :header-nav="navigatable.includes('ondemand-bikehire-summary')"
        />
        <q-step
          name="ondemand-bikehire-complete"
          :title="$t('booking_completed')"
          :caption="$t('confirmation')"
          :done="navigatable.includes('ondemand-bikehire-complete')"
          :header-nav="navigatable.includes('ondemand-bikehire-complete')"
        />
      </q-stepper>
    </div>
  </q-drawer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BikeHireSidebar',
  data () {
    return {
      show: !this.$q.platform.is.mobile
    }
  },
  computed: {
    ...mapGetters({
      stash: 'ondemand/stash',
      search: 'bikehire/search'
    }),
    currentStep: {
      get () {
        return this.$route.name
      },
      set (step) {
        this.$router.push({ name: step })
      }
    },
    navigatable () {
      let availablePaths = []
      switch (this.$route.name) {
      case 'ondemand-bikehire-complete':
        availablePaths.push('ondemand-bikehire-summary')
      case 'ondemand-bikehire-summary':
      case 'ondemand-bikehire-return-summary':
        availablePaths.push('ondemand-bikehire-results')
        break
      }
      return availablePaths
    },
    selectSummary () {
      return this.search === 'return'
        ? 'ondemand-bikehire-return-summary'
        : 'ondemand-bikehire-summary'
    }
  }
}
</script>

<style scoped lang="stylus">
.stepper
  border-radius 0
  border-bottom 1px solid grey
</style>
