<template>
  <q-card
    class="bike-hire-card results-card"
    :class="{'bg-primary-selected': selected }"
    @click.native="select"
  >
    <q-card-section class="logo">
      <img
        :src="`${location.logo}`"
        style="max-width:85%"
      >
    </q-card-section>
    <q-card-section class="bike-hire-loc-name loc-large">
      {{ location.pickup_station.name }}
    </q-card-section>
    <q-card-section class="bike-hire-loc-addr">
      {{ bikesAvailable }}
    </q-card-section>
    <q-card-section class="bike-hire-pic">
      <img :src="`${location.picture}`" style="max-width:50%; margin-left:35%">
    </q-card-section>
    <q-card-section class="bike-hire-price">
      {{ $t('price') }}: {{ $t('cost.per_day', {num: location.price.text} ) }}
    </q-card-section>
  </q-card>
</template>

<script>
export default {
  name: 'BikeHireResultsCard',
  props: ['location', 'selected'],
  computed: {
    bikesAvailable () {
      const available = this.location.pickup_station.available_bikes
      const total = this.location.pickup_station.number_of_slots
      return this.$tc('num.bikes_available', Number(available), { num: available, total: total })
    }
  },
  methods: {
    select () {
      this.$emit('selected', this.location.search_token)
    }
  }
}
</script>

<style scoped lang="stylus">
@import '../../../styles/ondemand'
.q-card__section
  padding 0 0 0 20px
</style>
