<template>
  <q-page class="page bg-white column items-center no-wrap">
    <div class="code" :class="$store.getters.partner.slug" />
    <div class="container">
      <div class="row">
        <q-card square class="bg-white">
          <q-card-section class="bg-primary text-white text-uppercase">
            {{ $t('content_type.bikehire') }}
          </q-card-section>

          <q-card-section class="column">
            <div class="q-col-gutter-sm content-center relative-position">
              <m-location-search
                ref="pickUp"
                class="col-12"
                :value="parameters.origin"
                :label="search === 'return' ? $t('drop_off_location') : $t('pick_up_location')"
                :placeholder="$t('choose_location_or_address')"
                no-shadow
                keep-open
                @selected="originSelect"
              />

              <div class="row col-12 q-col-gutter-sm">
                <div class="col-6">
                  <m-date-field
                    v-model="parameters.depart"
                    :min="minDepart"
                    :label="search === 'return' ? $t('date.drop_off') : $t('date.pick_up')"
                    :rounded="$q.platform.is.mobile"
                    :outlined="$q.platform.is.mobile"
                  />
                </div>
                <div class="col-6">
                  <m-time-field
                    v-model="parameters.depart"
                    :min="minDepart"
                    :minute-step="15"
                    :label="search === 'return' ? $t('time.drop_off') : $t('time.pick_up')"
                    :rounded="$q.platform.is.mobile"
                    :outlined="$q.platform.is.mobile"
                  />
                </div>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="row">
        <div class="col">
          <m-unified-users v-model="people" />
        </div>
      </div>
      <div v-if="bikehireStatus && bikehireStatus.brompton && bikehireStatus.brompton.status === 'Active booking'" class="row" style="width:80vw;max-width:600px">
        <m-banner
          v-if="bikehireStatus.brompton.booking_status === 'Return Pending'"
          color="warning"
          :message="$t(`bikehire.brompton.status['Return Pending']`)"
        />
        <m-banner
          v-else
          color="primary"
          :message="$t('bikehire.active_booking_text')"
        />
      </div>
      <div class="cta">
        <m-cta-button
          :label="$tc('find.dock', 2)"
          @ctaClick="submit"
        />
      </div>
    </div>
  </q-page>
</template>

<script>
import 'styles/ondemand.styl'
import { mapGetters } from 'vuex'
import store from 'store'
import i18n from 'i18n'
import { details } from 'api/places'
import { MUnifiedUsers, MLocationSearch, MBanner, MTimeField, MDateField, MCtaButton } from 'components/'
import authentication from 'mixins/authentication'
import searchLocation from 'mixins/searchLocation'
import loading from 'utils/loading'
import date from 'utils/date-time'
import { toTitleCase, handleErrors } from 'utils/utils'
import { hasRole } from 'utils/authentication'
const { addToDate, roundMinutesUp } = date

export default {
  name: 'OnDemandBikeHire',
  components: {
    MUnifiedUsers,
    MLocationSearch,
    MTimeField,
    MDateField,
    MBanner,
    MCtaButton
  },
  mixins: [authentication, searchLocation],
  data () {
    return {
      parameters: {
        traveller: null,
        requester: null,
        origin: null,
        destination: null,
        depart: addToDate(date.newDate(), { minutes: 5 })
      }
    }
  },
  computed: {
    ...mapGetters({
      bikehireStatus: 'bikehire/status',
      partner: 'partner',
      search: 'bikehire/search'
    }),
    people: {
      get () {
        return {
          traveller: this.parameters.traveller,
          requester: this.parameters.requester
        }
      },
      set (val) {
        this.parameters.requester = val.requester
        this.parameters.traveller = val.traveller
      }
    },
    disabled () {
      const locationsSet = this.parameters.origin
      return !(locationsSet && this.parameters.traveller && this.parameters.requester)
    },
    minDepart () {
      // Min departure param is (now + 30 minutes) rounded to the next 15 min
      return roundMinutesUp(addToDate(date.newDate(), { minutes: 30 }), 15)
    }
  },
  watch: {
    '$route.params.origin': {
      immediate: true,
      deep: true,
      handler (val) {
        if (typeof val === 'object') {
          const { label, value } = val
          this.parameters.origin = { label, value }
        }
      }
    },
    '$route.query.origin': {
      immediate: true,
      deep: true,
      handler (val) {
        if (typeof val === 'object') {
          this.parameters.origin = val
        }
      }
    }
  },
  async beforeRouteEnter (to, from, next) {
    if (hasRole('guest')) return next({ name: 'guest-mode' })
    const partner = store.getters['partner']

    loading.start({
      message: i18n.t('loading.checking.brompton'),
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })

    try {
      const member = await store.dispatch('bikehire/setBikehireStatus', store.getters.user.username)
      if (!member.status.startsWith('Active')) {
        const search = await store.getters['bikehire/search']
        loading.stop()
        if (search === 'search') return next()
        else return next({ name: 'bikehire-login-brompton' })
      } else if (member.status === 'Active booking') {
        await store.dispatch('bikehire/setSearchType', 'return')
      } else {
        await store.dispatch('bikehire/setSearchType', 'collection')
      }

      next(() => loading.stop())
    } catch (err) {
      handleErrors(err)
      next(false)
      loading.stop()
    }
  },
  created () {
    this.$store.commit('ondemand/clear')
  },
  activated () {
    this.$store.commit('ondemand/clear')
  },
  methods: {
    details,
    toTitleCase,
    async originSelect (value) {
      this.parameters.origin = value
    },
    submit () {
      this.$store.dispatch('ondemand/stash', { parameters: this.parameters })
      this.$router.push({ name: 'ondemand-bikehire-results' })
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>
